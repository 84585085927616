/* ====================
 * Exercise Transformer
 * ====================
 *
 * The transformer for the exercise.
 */

import {
  G,
  W,
  all,
  map,
  not,
  pick,
  asArray,
  extend,
  invoke,
  partial,
  compose,
  firstOf,
  indexBy,
  identity
} from '@2l/utils'

import Transformer from './Transformer.js'

const lower = invoke('toLowerCase')
const reCurlyWord = /(\{\w+\})/gi
const reCurly = /(\{|\}|\[|\])/gi

const curlies = W('[ ] { }')

const originWord = G('word.origin')

const buildVideoObject = all(G('media.video._id'),
  pick({ video: 'media.video' }))
const buildImageObject = all(not(G('media.video._id')), G('media.image._id'),
  pick({ image: 'media.image' }))
const buildAvatarsObject = all(G('media.avatars.mate._id'),
  pick({
    'avatars.mate.pic': 'media.avatars.mate.l',
    'avatars.mate.placeholder': 'media.avatars.mate.s',
    'avatars.user.pic': 'media.avatars.user.l',
    'avatars.user.placeholder': 'media.avatars.user.s'
  }))

const buildDefaultObject = pick(
  ...W('_id type courseId lessonId isRepeatable'),
  {
    hint: 'content.hint',
    text: 'content.text',

    voice: 'media.voice',
    isCompleted: 'isComplete'
  }
)

const buildExplainType = pick({
  description: 'content.description',
  translation: 'content.translation'
})

const buildExplainWordType = pick({
  origin: 'word.origin',
  translatedMeanings: 'word.meanings',
  exampleOrigin: 'word.example.origin',
  exampleTranslation: 'word.example.translation'
})

const buildPlayableHash = pick({
  playableKeys: compose(
    firstOf(
      // for dialogues
      all(
        G('content.messages'),
        compose(
          G('content.messages'),
          partial(map, G('answers.correct'))
        )
      ),
      // for words
      G('word.origin'),
      // default case
      G('content.answers.correct')
    ), asArray, partial(indexBy, identity())
  )
})

export default class ExerciseTransformer extends Transformer {
  /**
   * Method used to transform a fetched exercise.
   *
   * @param exercise The fetched exercise.
   *
   * @returns {Object} The transformed exercise.
   */
  static fetch (exercise) {
    switch (exercise.type) {
      case 'explainWord':
        return makeExerciseObject(exercise, buildExplainWordType(exercise))
      case 'explain':
      case 'explainWithVideo':
        return makeExerciseObject(exercise, buildExplainType(exercise))
      case 'dialog':
        return makeExerciseObject(exercise, buildDialogType(exercise))
      case 'composePhraseByVideo':
      case 'composePhraseByText':
      case 'composePhraseByImage':
        return makeExerciseObject(exercise, buildComposePhraseByText(exercise))
      case 'chooseByVideo':
      case 'chooseByImage':
        return makeExerciseObject(exercise, buildChooseByVideo(exercise))
      case 'chooseAnswerByText':
        return makeExerciseObject(exercise, buildChooseAnswerByText(exercise))
      case 'composeWord':
        return makeExerciseObject(exercise, buildComposeWord(exercise))
      default:
        throw new Error(`Invalid exercise type: ${exercise.type}`)
    }
  }
}

function makeExerciseObject (originalExercise, normalizedExercise) {
  return extend(
    buildDefaultObject(originalExercise),
    buildAvatarsObject(originalExercise),
    buildVideoObject(originalExercise),
    buildImageObject(originalExercise),
    buildPlayableHash(originalExercise),
    normalizedExercise
  )
}

function buildComposePhraseByText (exercise) {
  const { extraBlocks, answers } = exercise.content
  const { correct, incorrect } = answers

  let uniqueBlocks = []
  if (extraBlocks) {
    for (const word of extraBlocks.split(' ')) {
      if (!correct.includes(word)) { uniqueBlocks += word }
    }
  }

  const variants = [].concat(correct, incorrect, uniqueBlocks)

  const correctPhrase = correct.replace(reCurly, '%')
  const correctPhraseKey = correct.replace(reCurly, '')

  const correctPartsIndex = {}
  const selectableParts = {}
  const partsByIndex = {}
  const parts = {}

  let correctVariants = 0
  let selectableIndex = 0
  let index = 0

  for (const variant of variants) {
    if (!variant) { continue }

    const components = variant.split(reCurly)

    for (const component of components) {
      if (!component.trim() || curlies.includes(component)) { continue }

      const correctWords = correctPhrase.split('%')
      const success = correctWords.includes(component)

      const selectable = variant.toLowerCase()
        .includes(`{${component.toLowerCase()}}`)

      const indexes = parts[component] || []
      const notDot = component !== '.'

      partsByIndex[index] = component

      if (selectable) {
        correctPartsIndex[selectableIndex] = component
        selectableParts[index] = selectableIndex++
      }

      parts[component] = indexes.concat(success ? index++ : null)
      if (success && selectable && notDot) { correctVariants++ }
    }
  }

  return {
    parts,
    partsByIndex,
    correctPhrase,
    correctPhraseKey,
    correctVariants,
    selectableParts,
    correctPartsIndex,
    playableKeys: { [correctPhraseKey]: correctPhraseKey },

    correctPhraseLength: index
  }
}

function buildChooseByVideo (exercise) {
  const { extraBlocks } = exercise.content
  const { correct, incorrect } = exercise.content.answers

  const answers = {}
  const variants = [].concat(correct, incorrect, extraBlocks)

  for (const variant of variants) {
    if (variant) { answers[variant] = variant === correct }
  }

  return { answers }
}

function buildChooseAnswerByText (exercise) {
  const { hint } = exercise.content
  const { correct, incorrect } = exercise.content.answers

  const answers = {}
  const variants = [].concat(correct, incorrect)

  for (const variant of variants) {
    if (variant) { answers[variant] = variant === correct }
  }

  return {
    hint,
    correctWord: correct,
    answers
  }
}

function buildComposeWord (exercise) {
  const { text, answers, extraBlocks } = exercise.content
  const { correct } = answers

  const symbols = {}

  for (let i = 0, len = correct.length; i < len; i++) {
    const index = lower(correct[i])
    symbols[index] = (symbols[correct[i]] || 0) + 1
  }

  if (extraBlocks) {
    const extraSymbols = extraBlocks.replace(reCurly, '')
    for (let i = 0, len = extraSymbols.length; i < len; i++) {
      if (!symbols[extraSymbols[i]]) {
        symbols[extraSymbols[i]] = 1
      }
    }
  }

  const correctPhrase = text.replace(reCurlyWord, correct)

  const originKey = originWord(exercise) || correctPhrase

  return {
    symbols,
    correctPhrase,
    correctWordOriginal: correct,
    playableKeys: { [originKey]: originKey }
  }
}

function buildDialogType (exercise) {
  const { messages } = exercise.content

  const replicas = []
  const originReplicas = []

  for (const message of messages) {
    const { correct } = message.answers

    replicas.push(buildReplica(message))
    originReplicas.push(correct)
  }

  const index = indexBy((q, i) => i, replicas)
  const questionKeys = map(G('question'), replicas)

  return { replicas: index, questions: questionKeys }
}

function buildReplica (message) {
  const {
    hint, correct, incorrect, question
  } = message.answers

  return {
    hint,
    correct,
    question,

    answers: map(answer => ({
      correct: answer === correct,
      text: answer
    }), incorrect.concat(correct))
  }
}
