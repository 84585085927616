/* ============
 * Mutations for the offers module
 * ============
 *
 * The mutations that are available on the
 * offers module.
 */

import { G, has } from '@2l/utils'

import {
  SET_IS_PLANS_FROM_QUERY,
  SET_PLAN_IDS,
  SET_QUERY_TIER_TYPE
} from '@/store/modules/crmTierExperiment/mutation-types'

export default {
  [SET_IS_PLANS_FROM_QUERY] (state, route) {
    state.isPlansFromQuery = has('query.planIds')(route)
  },
  [SET_QUERY_TIER_TYPE] (state, route) {
    state.queryTierType = G('query.tier_type')(route)
  },
  [SET_PLAN_IDS] (state, { routePlanGroup, allPlanGroups, route }) {
    const storageTier = localStorage.getItem('tier')

    const getPlansFromQueryString = (params) => {
      const { planIds } = params
      return planIds.split(',').slice(0, 2)
    }

    if (state.isPlansFromQuery) {
      state.planIds = getPlansFromQueryString(route.query)
    } else if (state.queryTierType) {
      state.planIds = allPlanGroups[routePlanGroup][state.queryTierType]
    } else if (storageTier) {
      state.planIds = allPlanGroups[routePlanGroup][storageTier]
    } else {
      state.planIds = allPlanGroups[routePlanGroup].default
    }
  }
}
