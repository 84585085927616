/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import {
  G, set, pick, identity, firstOf, indexBy, oneOf, includes, not, eql
} from '@2l/utils'
import Transformer from './Transformer.js'

const accountFields = [
  'bill',
  '_id',
  'lang',
  'activeProfile',
  'login',
  'email',
  'score',
  'materialAccess',
  'subscription',
  'navigation',
  'learningMaterials',
  'registerAsAnonymous',
  'registerBySocNet',
  'profiles',
  'createDate',
  'hasInstallSigns'
]

const getCoursesType = oneOf({
  roadmap: [
    {
      'lang.ne': ['cs', 'nl', 'fi', 'hu', 'no', 'fa', 'sv'],
      roadMapAllowed: true
    },
    { lang: ['ms', 'lv', 'lt'] }
  ]
}, 'courses')

const checkCoursesView = (user) => {
  const coursesType = getCoursesType({
    lang: user.lang,
    activeProfile: user.activeProfile,
    roadMapAllowed: user.roadMapAllowed
  })

  if (coursesType === 'courses') {
    return true
  }

  return false
}

const isRoadMapAllowed = eql('coursesView', 'roadmap')
const hasProgress = G('courseStat.completedLessons.length')

const getLearningProfile = (user) => {
  const idx = indexBy('lang', user.profiles || [])
  return idx[user.activeProfile] || idx.en || {}
}

const buildAccount = pick(
  ...accountFields,
  {
    coursesAvailable: not(includes(identity(['da']), G('lang'))),
    id: G('_id'),
    hasAcceptedAccounts: G('hasAcceptedAccounts'),
    onboardingFinished: G('onboardingFinished'),
    'settings.name': firstOf(G('settings.name'), identity(' ')),
    'settings.avatar': firstOf(G('settings.avatar'), identity('')),
    languageLevel: G('languageLevel')
  }
)

export default class AccountTransformer extends Transformer {
  /**
   * Method used to transform a fetched account.
   *
   * @param account The fetched account.
   *
   * @returns {Object} The transformed account.
   */
  static fetch (user) {
    const account = buildAccount(user)
    const learningProfile = getLearningProfile(user)

    account.roadMapAllowed = isRoadMapAllowed(learningProfile)
    account.coursesUser = checkCoursesView(account)
    account.hasCompletedLessons = hasProgress(learningProfile)

    const [firstName, lastName] = account.settings.name.split(' ')
    Object.assign(account.settings, { firstName, lastName })

    set(account, 'meta.analytics', [['set', 'userId', account.id]])

    return account
  }

  /**
   * Method used to transform a send account.
   *
   * @param account The account to be send.
   *
   * @returns {Object} The transformed account.
   */
  static send (account) {
    return {
      email: account.email,
      login: account.login
    }
  }
}
