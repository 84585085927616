/* ============
 * Getters for the crm tiers experiment
 * ============
 */

import { G } from '@2l/utils'

export default {
  getIsPlansFromQuery: G('isPlansFromQuery'),
  getQueryTierType: G('queryTierType'),
  getPlanIds: G('planIds')
}
