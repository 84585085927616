/* ============
 * State of the offers module
 * ============
 *
 * The initial state of the offers module.
 */

export default {
  isPlansFromQuery: false,
  queryTierType: false,
  planIds: null
}
