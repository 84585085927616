/* ============
 * Actions for the crm tiers experiment
 * ============
 */

import * as types from './mutation-types.js'

export default {
  setCrmExperimentData
}

export function setCrmExperimentData ({ commit, state }, payload) {
  commit(types.SET_IS_PLANS_FROM_QUERY, payload.route)
  commit(types.SET_QUERY_TIER_TYPE, payload.route)
  commit(types.SET_PLAN_IDS, payload)
}
