/* ============
 * Mutation types for crm tier experiment
 * ============
 */

export const SET_IS_PLANS_FROM_QUERY = 'SET_IS_PLANS_FROM_QUERY'
export const SET_QUERY_TIER_TYPE = 'SET_QUERY_TIER_TYPE'
export const SET_PLAN_IDS = 'SET_PLAN_IDS'

export default {
  SET_IS_PLANS_FROM_QUERY,
  SET_QUERY_TIER_TYPE,
  SET_PLAN_IDS
}
